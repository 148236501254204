import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { navigate } from '@reach/router';
import { Index } from 'elasticlunr';
import { SearchContext } from '@components/searchContext';
import CategoryModal, { CategoryModalStyles } from '@components/categoryModal';
import Toggle from '@components/toggle';
import Modal from '@components/modal';
import { SearchWrapperStyles } from '@styles/search';
import SearchIcon from '@icons/search';
import ChevronRightIcon from '@icons/chevronRight';
import CloseBtn from '@icons/closeBtn';

export default class Search extends Component {
  static propTypes = {
    searchIndex: PropTypes.object,
    initial: PropTypes.string,
    page: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.initCheck = false;
    // initializing this here so i can set it from inside the consumer and access it in componentDidMount
    this.handleSearch = null;
  }

  componentDidMount = () => {
    // rendering initial search results
    const { initial } = this.props;

    if (initial && !this.initCheck) {
      this.handleSearch(initial);
      this.initCheck = true;
    }
  };

  getIndex = () => {
    const { searchIndex } = this.props;
    return this.index ? this.index : Index.load(searchIndex);
  };

  render() {
    const { page } = this.props;

    return (
      <SearchWrapperStyles page={page}>
        <SearchContext.Consumer>
          {({ search, setSearch, setResults }) => {
            this.handleSearch = val => {
              const index = this.getIndex();
              setSearch(val);
              setResults(
                index
                  .search(val, { expand: true })
                  .map(({ ref }) => index.documentStore.getDoc(ref))
              );

              if (page === `/search`) {
                navigate(`/search?q=${encodeURIComponent(val)}`);
              }
            };
            return (
              <div className="search-bar">
                <div className="inner">
                  {page !== `/` && (
                    <CategoryModalStyles page={page}>
                      <Toggle>
                        {({ on, toggle }) => (
                          <>
                            {on && (
                              <Modal toggle={toggle}>
                                <CategoryModal toggle={toggle} />
                              </Modal>
                            )}
                            <div className="mobile-categories-btn-wrapper desktop">
                              <button
                                type="button"
                                onClick={toggle}
                                aria-label="Browse by category"
                              >
                                <span>Browse by category</span>
                                <ChevronRightIcon />
                              </button>
                            </div>
                          </>
                        )}
                      </Toggle>
                    </CategoryModalStyles>
                  )}

                  <form
                    onSubmit={e => {
                      e.preventDefault();
                      const inputVal = e.target.querySelector(`input`).value;

                      e.target.querySelector(`input`).blur();
                      e.target.querySelector(`button`).blur();

                      if (!inputVal) return null;

                      if (window.location.pathname !== `/search`) {
                        navigate(`/search?q=${encodeURIComponent(inputVal)}`);
                      }
                    }}
                  >
                    <div id="searchInputWrapper">
                      <label htmlFor="searchInput" aria-label="Search">
                        <input
                          type="text"
                          id="searchInput"
                          value={search}
                          autoComplete="off"
                          placeholder="Search our available inventory..."
                          onChange={e => this.handleSearch(e.target.value)}
                        />
                      </label>
                      {search && (
                        <button
                          type="button"
                          aria-label="Search"
                          onClick={() => {
                            this.handleSearch(``);
                            document
                              .querySelector(`#searchInputWrapper input`)
                              .focus();
                          }}
                        >
                          <div id="clearBtnInner">
                            <CloseBtn />
                          </div>
                        </button>
                      )}
                    </div>
                    <button
                      type="submit"
                      aria-label="Search"
                      onClick={e => e.target.blur()}
                    >
                      <span className="search-icon mobile">
                        <SearchIcon />
                      </span>
                      <span className="search-text desktop">Search</span>
                    </button>
                  </form>
                </div>
              </div>
            );
          }}
        </SearchContext.Consumer>
      </SearchWrapperStyles>
    );
  }
}
