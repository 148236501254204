import styled from 'styled-components';

const SearchWrapperStyles = styled.div`
  margin: 0 auto;
  position: relative;
  width: 100%;

  form {
    display: flex;
    justify-content: space-between;
    margin: 0;
    max-width: ${props => props.theme.maxWidth};
    width: 100%;

    @media screen and (min-width: 1200px) {
      width: 100%;
    }
  }

  #searchInputWrapper {
    height: 4rem;
    position: relative;
    width: calc(100% - 5.5rem);

    @media screen and (min-width: 990px) {
      width: calc(100% - 22rem);
    }
  }

  #searchInputWrapper input {
    background: ${props => props.theme.white};
    border-radius: ${props => props.theme.br};
    border: 0.1rem solid ${props => props.theme.offwhite};
    font-weight: 300;
    height: 4rem;
    letter-spacing: 0.025rem;
    margin: ${({ page }) => (page !== `/` ? `0 1rem 0 0` : `0`)};
    opacity: 0.965;
    outline: none;
    padding: 0.5rem 1.5rem;
    width: 100%;
  }

  #searchInputWrapper input:focus {
    @media screen and (min-width: 990px) {
      background: ${props => props.theme.offwhite};
    }
  }

  #searchInputWrapper button {
    background: none;
    height: 4rem;
    padding: 0.75rem;
    position: absolute;
    right: 0.5rem;
    top: 0;
    width: 4rem;

    &:hover #clearBtnInner {
      background: ${props => props.theme.red};
    }

    &:focus #clearBtnInner {
      background: ${props => props.theme.lightred};
    }
  }

  #searchInputWrapper #clearBtnInner {
    align-items: center;
    background: ${props => props.theme.grey};
    border-radius: 50%;
    display: flex;
    height: 2.2rem;
    justify-content: center;
    margin: 0;
    width: 2.2rem;

    svg {
      height: 1.2rem;
      width: 1.2rem;
    }

    svg path {
      fill: ${props => props.theme.white};
    }
  }

  button {
    border-radius: ${props => props.theme.br};
    border: none;
    cursor: pointer;
    height: 4rem;
    outline: none;
  }

  button[type='submit'] {
    background: ${props => props.theme.blue};
    color: ${props => props.theme.white};
    cursor: pointer;
    padding: 0.75rem 1.25rem;
    width: 5rem;

    @media screen and (min-width: 768px) {
      width: 10rem;
    }

    @media screen and (min-width: 990px) {
      width: 20rem;
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      background: ${props => props.theme.darkblue};
    }

    &:not(:disabled):focus {
      background: ${props => props.theme.lightblue};
      box-shadow: 0 0 0.2rem 0.4rem ${props => props.theme.clearblue};
    }
  }

  /* search button icon - mobile only */
  button .search-icon.mobile {
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  /* search button text */
  button .search-text.desktop {
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
`;

const FixedSearchStyles = styled.div`
  @media screen and (min-width: 768px) {
    height: 7.6rem;
  }

  .search-bar {
    background: ${props => props.theme.blue};
    border-bottom: 0.1rem solid ${props => props.theme.offwhite};
    bottom: -0.1rem;
    left: 0;
    opacity: 0.95;
    position: fixed;
    right: 0;
    z-index: 5000;

    @media screen and (min-width: 768px) {
      background: ${props => props.theme.white};
      bottom: auto;
      opacity: 1;
    }
  }

  .search-bar .inner {
    display: flex;
    padding: 1.2rem ${props => props.theme.gutterSm};

    @media screen and (min-width: 990px) {
      padding: 2rem ${props => props.theme.gutterLg};
    }
  }

  form,
  form input,
  button {
    height: 4rem;
  }

  button[type='submit'] {
    padding: 0.5rem 0.75rem;

    @media screen and (max-width: 767px) {
      width: 4rem;
    }
  }
`;

export { SearchWrapperStyles, FixedSearchStyles };
