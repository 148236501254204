import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Search from '@components/search';

export default function SearchWrapper({ initial, page }) {
  const data = useStaticQuery(graphql`
    query SearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `);

  return (
    <Search
      searchIndex={data.siteSearchIndex.index}
      initial={initial}
      page={page}
    />
  );
}

SearchWrapper.propTypes = {
  initial: PropTypes.string,
  page: PropTypes.string,
};
